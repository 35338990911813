import styled from "styled-components";

const PageWrapperContainer = styled.div`
	font-family: "Futura book";
	width: 100%;
	color: white;
	margin: 0 auto;
	max-width: 1500px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	/* margin-top: 100px; */
	letter-spacing: 0.5px;

	@media (max-width: 768px) {
		max-width: 340px;
		/* padding: 0 30px; */
	}
`;

export default PageWrapperContainer;
