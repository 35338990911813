import * as React from "react";
import styled from "styled-components";

import PageWrapperContainer from "../PageWrapper/PageWrapperContainer";

// markup
const Container = ({ children }) => {
	return (
		<>
			<PageWrapperContainer>
				<ContainerWrapper>{children}</ContainerWrapper>
			</PageWrapperContainer>
		</>
	);
};

export default Container;

const ContainerWrapper = styled.div`
	max-width: 1080px;
	margin: 0 auto;
	width: 100%;
	/* margin-top: 80px; */

	@media (max-width: 768px) {
		max-width: 340px;
	}
`;
