import * as React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import "fontsource-crete-round";

// markup
const Logo = () => {
	return (
		<LogoText href="/">
			Marvin <br /> Holleman.
		</LogoText>
	);
};

export default Logo;

const LogoText = styled.a`
	/* position: fixed; */
	z-index: 999;
	font-size: 1.4rem;
	margin: 0;
	color: #fff;
	width: fit-content;
	transform: rotate(90deg);
	display: inline-block;
	text-decoration: none;
	margin-left: -20px;

	&:hover {
		cursor: pointer;
		color: #839cb2;
	}
`;
