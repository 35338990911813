import * as React from "react";
import styled from "styled-components";
import Bounce from "react-reveal/Bounce";

import Container from "../Container";

import { createGlobalStyle } from "styled-components";

import Logo from "../Logo";
import FuturaBook from "../../fonts/FuturaPTBook.otf";
import FuturaBold from "../../fonts/FuturaPTBold.otf";
import FuturaMedium from "../../fonts/FuturaPTMedium.otf";

// markup
const PageWrapper = ({ children }) => {
	return (
		<>
			{/* <PageTransitionAnimation className="swiper"></PageTransitionAnimation> */}
			<PageWrapperContainer className="app">
				<GlobalStyle />
				<Bounce left duration={2000} delay={6000}>
					<LogoWrapper>
						<Logo />
					</LogoWrapper>
				</Bounce>
			</PageWrapperContainer>
			<div className="page-content">{children}</div>
			<PageWrapperContainer>
				<FooterWrapper>
					<FooterText>Marvin Holleman</FooterText>
				</FooterWrapper>
			</PageWrapperContainer>
		</>
	);
};

export default PageWrapper;

const GlobalStyle = createGlobalStyle`

	@font-face {
		font-family: "Futura Bold";
		src: url(${FuturaBold});
	}

	@font-face {
		font-family: "Futura medium";
		src: url(${FuturaMedium});
	}

	@font-face {
		font-family: "Futura book";
		src: url(${FuturaBook});
	}

  body {
    margin:0;
	background-color: #000;
	scroll-behavior: smooth;
	overflow-x: hidden;
  }

  ::-webkit-scrollbar:horizontal {
    display: none;
}
`;

const PageTransitionAnimation = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	z-index: 999;
	background-color: white;
	/* overflow: hidden; */
`;

const PageWrapperContainer = styled.div`
	font-family: "Futura book";
	width: 100%;
	color: white;
	margin: 80px auto 0;
	max-width: 1240px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin-top: 100px;
	letter-spacing: 0.5px;
`;

const LogoWrapper = styled.div`
	width: fit-content;
	height: 100%;
`;

const FooterWrapper = styled.div`
	position: relative;
	bottom: 0;
`;

const FooterText = styled.p`
	color: #fff;
`;
